import { template as template_e5f620e56278444c96118fc9b6527d22 } from "@ember/template-compiler";
const FKText = template_e5f620e56278444c96118fc9b6527d22(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
